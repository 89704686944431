












































import { Patient } from '@/models';
import { defineComponent, onMounted, reactive, ref } from '@/plugins/composition';
import { storage } from '@/main';
import { useFormValidation } from '@/composables/useFormValidation';

export default defineComponent({
  name: 'DetailsForm',
  setup() {
    const valid = ref(false);
    const formData = reactive({
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
    });

    const user = ref<Patient | null>(null);

    const { errors, mapErrors } = useFormValidation(formData);

    onMounted(async () => {
      user.value = await Patient.find(storage.auth.asPatient.data.uuid);
      formData.firstName = user.value.data.firstName;
      formData.lastName = user.value.data.lastName;
      formData.phone = user.value.data.phone ?? '';
      formData.email = user.value.data.email;
    });

    const update = async () => {
      await user.value?.update(formData).catch((error) => {
        mapErrors(error);
      });
    };

    return {
      valid,
      formData,
      user,
      update,
      errors,
    };
  },
});
