


































import Component from 'vue-class-component';
import Vue from 'vue';
import { VModel, Watch } from 'vue-property-decorator';
import { formatDate } from '@/utils/date';
import { formatISO } from 'date-fns/fp';
import { parseISO } from 'date-fns';

@Component({
  name: 'BirthdayPicker',
})
export default class extends Vue {
  @VModel() readonly dob!: string;

  menu = false;
  activePicker: string | null = null;

  get date(): string {
    return this.dob?.substring(0, 10);
  }

  set date(date: string) {
    this.$emit('input', date);
  }

  get formattedDob(): string | null {
    return formatDate(this.dob);
  }

  @Watch('menu')
  onMenuChanged(val: string): void {
    val && setTimeout(() => (this.activePicker = 'YEAR'));
  }

  save(date: string): void {
    (this.$refs.menu as Vue & { save: (date: string) => void }).save(date);
    this.$emit('input', formatISO(parseISO(date)));
  }
}
