






















import DetailsForm from '../components/DetailsForm.vue';
import PasswordForm from '../components/PasswordForm.vue';
import ProfileTile from '@/domains/practitioner/modules/shared/components/ProfileTile.vue';
import { defineComponent } from '@/plugins/composition';

export default defineComponent({
  components: { ProfileTile, DetailsForm, PasswordForm },
  name: 'MyDetailsPage',
});
