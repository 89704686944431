






















import Component from 'vue-class-component';
import Vue from 'vue';
import { VModel } from 'vue-property-decorator';

@Component({
  name: 'GenderInput',
})
export default class extends Vue {
  @VModel({ type: String }) readonly gender!: string;
  genderSearch: string | null = null;

  items = [{ header: 'You can type any gender or select one below' }, 'Male', 'Female', 'Other'];
}
