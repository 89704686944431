import { InputValidationRule } from 'vuetify';

type RuleResult = string | boolean;

const emailRegex =
  /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;

// const mixedCase = /^((?=\S*?[A-Z ])(?=\S*?[a-z ])((?=\S*?[0-9 ])|(?=\S*?[^\w*])).{8,})$/;

// export const password = (): InputValidationRule[] => [
//   (v: string): boolean | string => v.length >= 8 || 'Password must be at least 8 characters',
//   (v: string): boolean | string => mixedCase.test(v) || 'Must contain uppercase and lowercase letters and one number or special character',
// ];

export function email(message = 'Must be a valid email'): InputValidationRule {
  return (v: string | null): string | boolean => (!!v && emailRegex.test(v)) || message;
}

export function required(message = 'Field is required'): InputValidationRule {
  return (v: string | null): string | boolean => !!v || message;
}

export function minimum(min: string): InputValidationRule {
  return (v: string | null | number): string | boolean => !v || v >= parseInt(min) || `Must be more than ${min}`;
}

export function maximum(max: string): InputValidationRule {
  return (v: string | null | number): string | boolean => !v || v < parseInt(max) || `Must be less than ${max}`;
}

export const password = (): InputValidationRule[] => [
  (v: string): RuleResult => v.length >= 8 || 'Password must be at least 8 characters',
];

export const rules = {
  password,
  email,
  required,
  min: minimum,
  max: maximum,
};

export const ruleFunctions = {
  email: email(),
  required: required(),
  // password: password(),
};
